import { useState, useEffect, useReducer } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";

const reducer = (state, action) => {
  const currentState = Array.isArray(state) ? state : [];

  switch (action.type) {
    case "LOAD_WHATSAPPS":
      return Array.isArray(action.payload) ? action.payload : [action.payload].filter(Boolean);
    case "UPDATE_WHATSAPPS":
      const whatsApp = action.payload;
      if (!whatsApp) return currentState;
      
      const whatsAppIndex = currentState.findIndex((s) => s?.id === whatsApp.id);

      if (whatsAppIndex !== -1) {
        currentState[whatsAppIndex] = whatsApp;
        return [...currentState];
      } else {
        return [whatsApp, ...currentState];
      }
    case "UPDATE_SESSION":
      const whatsAppSession = action.payload;
      const whatsAppSessionIndex = currentState.findIndex(
        (s) => s.id === whatsAppSession.id
      );

      if (whatsAppSessionIndex !== -1) {
        currentState[whatsAppSessionIndex].status = whatsAppSession.status;
        currentState[whatsAppSessionIndex].qrcode = whatsAppSession.qrcode;
        currentState[whatsAppSessionIndex].retries = whatsAppSession.retries;
        return [...currentState];
      } else {
        return [...currentState];
      }
    case "DELETE_WHATSAPPS":
      const whatsAppId = action.payload;
      const whatsAppList = currentState.filter((s) => s.id !== whatsAppId);
      return [...whatsAppList];
    case "RESET":
      return [];
    default:
      return currentState;
  }
};

const useWhatsApps = () => {
  const [loading, setLoading] = useState(true);
  const [whatsApps, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    setLoading(true);
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/whatsapp/one?session=0");
        const safeData = data === null || data === undefined ? [] : 
                        Array.isArray(data) ? data : [data];
        console.log(safeData, "safeData")
        dispatch({ type: "LOAD_WHATSAPPS", payload: safeData });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch({ type: "LOAD_WHATSAPPS", payload: [] });
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-whatsapp`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
      }
    });

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return { whatsApps, loading };
};

export default useWhatsApps;
