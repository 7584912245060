import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import { Paper, makeStyles } from "@material-ui/core";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TagsContainer } from "../TagsContainer";
import { socketConnection } from "../../services/socket";
import ArrowLeftIcon from "../../assets/icon/ArrowLeftIcon";
import backgroundChat from '../../assets/img/backgroundChat.png';
import { TicketsContext } from "../../context/Tickets/TicketsContext";
const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    width: "100%",
    marginRight: -drawerWidth,
    backgroundImage: `url(${backgroundChat})`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  containerHeader: {
    margin: "0 10px 0 10px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
    },
  },
}));

const Ticket = ({setOption}) => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { getCurrentTicketInfo, ticketsList, setTicketsList, fetchTags, loadingTags } = useContext(TicketsContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  // Cargar tags al montar el componente
  useEffect(() => {
    // Forzar la actualización solo si no hay tags
    fetchTags(false);
  }, [fetchTags]);

  useEffect(() => {
    let mounted = true;
    const fetchTicket = async () => {
      if (!ticketId) return;
      
      try {
        setLoadingTicket(true);
        // Primero intentamos obtener del contexto/cache
        const contextTicket = getCurrentTicketInfo();
        const cachedTicket = ticketsList.find(t => t.uuid === ticketId);
        const ticketToUse = cachedTicket || contextTicket;

        if (ticketToUse) {
          const { queueId } = ticketToUse;
          const { queues, profile } = user;

          const queueAllowed = queues.find((q) => q.id === queueId);
          if (queueAllowed === undefined && profile !== "admin") {
            toast.error("Acceso no permitido");
            history.push("/tickets");
            return;
          }

          if (mounted) {
            setContact(ticketToUse.contact);
            setTicket(ticketToUse);
            setLoadingTicket(false);
          }
        } else {
          // Solo hacemos la llamada API si no tenemos el ticket en cache/contexto
          const { data } = await api.get("/tickets/u/" + ticketId);
          
          if (mounted) {
            setContact(data.contact);
            setTicket(data);
            setLoadingTicket(false);
            
            // Actualizar el contexto solo si el ticket no existe
            setTicketsList(prevTickets => {
              if (!prevTickets.some(t => t.uuid === data.uuid)) {
                return [data, ...prevTickets];
              }
              return prevTickets;
            });
          }
        }
      } catch (err) {
        if (mounted) {
          setLoadingTicket(false);
          toastError(err);
        }
      }
    };

    fetchTicket();
    return () => {
      mounted = false;
    };
  }, [ticketId]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderTicketInfo = () => {
    if (ticket?.id && contact?.id) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
          setOption={setOption}
        />
      );
    }
    return null;
  };

  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        ></MessagesList>
        <MessageInput
          ticketId={ticket.id}
          contactId={ticket.contactId}
          ticketStatus={ticket.status}
        />
      </>
    );
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <div className={classes.containerHeader}>
          <TicketHeader 
          loading={loadingTicket}>
            {renderTicketInfo()}
            <TicketActionButtons
              ticket={ticket}
              handleDrawerOpen={handleDrawerOpen}
            />
          </TicketHeader>
          {!loadingTicket && <TagsContainer ticket={ticket} loading={loadingTags} />}
        </div>
        <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loadingTicket}
        ticket={ticket}
      />
    </div>
  );
};

export default Ticket;
